import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import router from "./router/index";
import Vuex from "vuex";
// import DisableAutocomplete from "vue-disable-autocomplete";
import store from './store/index';
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import exportingInit from "highcharts/modules/exporting";


// highchart
Stock(Highcharts);
exportingInit(Highcharts);
Vue.use(HighchartsVue);

// Vuex state
Vue.use(Vuex);
// Vue.use(DisableAutocomplete);

// import mixins
import appMixins from '@/mixins/globalMixins'

// init global mixins
Vue.mixin(appMixins)

// create event bus
export const EventBus = new Vue

//  init axios
Vue.prototype.axios = axios;

Vue.config.productionTip = false;

//axios interceptors

let isRefreshing = false

// axios.interceptors.response.use(
//     (response) => {
//         return response
//     },
//     (error) => {
//         const {
//             config,
//             response: { status, data },
//         } = error

//         if (status === 401 && data.message === 'Expired token') {
//             if (!isRefreshing) {
//                 isRefreshing = true
//                 store
//                     .dispatch('refreshToken')
//                     .then(({ status }) => {
//                         if (status === 200 || status === 204) {
//                             isRefreshing = false
//                         }
//                     })
//                     .catch((error) => {
//                         console.log(error)
//                     })
//             }
//         }
//     }
// )



new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
  created() {
    this.$store.dispatch('checkUserLocalStorage');
    //alert(this.$store.getters.token)

    //renew jst every 10 min
    var renewjwt_interval =null;

    this.$store.dispatch('refreshToken'); //cek token first

    renewjwt_interval = setInterval(()=>{  //then buat interval setiap 10 min utk refresh token

        this.$store.dispatch('refreshToken');  //this.$store.dispatch utk panggil globfunc, this.$store.getters utk panggil globvar

    }, 10 * 60 * 1000);
    //end of renew jst every 10 min

},
}).$mount("#app");






//////////bwh ni utk token & cookies, rujukan=raja punye zerohunger atau https://www.w3schools.com/js/js_cookies.asp =

// window._setCookie = function(cname, cvalue, exdays){
//   var d = new Date();
//   d.setTime(d.getTime() + (15 * 60 * 1000));  //cookie 15min expiry
//   var expires = "expires="+d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
//   return d;
// }


// window._getCookie = function(cname){
//     var name = cname + "=";
//     var ca = document.cookie.split(';');

//     for(var i = 0; i < ca.length; i++) {
//       var c = ca[i];
//       while (c.charAt(0) == ' ') {
//           c = c.substring(1);
//       }
//       if (c.indexOf(name) == 0) {
//           return c.substring(name.length, c.length);
//       }
//     }
//     return "";
// }


// window._resetCookie = function(){
//     window._setCookie("jwt_token", "", 365);
//     // setCookie("name", "", 365);
//     // setCookie("address", "", 365);
//     // setCookie("ngoType", "", 365);
//     // setCookie("phone", "", 365);
//     // setCookie("token", "", 365);
//     // setCookie("coverage", "", 365);
//     //alert('cookies cleared');
// }

// /////////bwh ni utk read n renew jwt token

// window._readjwt = function(){

//   let token = window._getCookie("jwt_token");

//   let readtoken = null;

//   try{
//     readtoken = JSON.parse(window.atob(token.split('.')[1]));
//   }
//   catch(err){
//     console.log(err);    
//   }  
  
//   return readtoken;

// }


// var _renew_my_token_interval = null;

// renewmytoken_timer();

// function renewmytoken_timer(){

//     renewmytoken();

//     clearInterval(_renew_my_token_interval);

//     _renew_my_token_interval = setInterval(()=>{
//         renewmytoken();
//     }, 10 * 60 * 1000);  //setiap 10 minit renew

// }



// function renewmytoken(){

//     console.log('renewing your token..');

//     var xhttp = new XMLHttpRequest();
//     xhttp.onreadystatechange = function () {
//     if (this.readyState == 4 && this.status == 200) {

//         var mytoken = ( JSON.parse(this.responseText) ).newjwt;

//         window._setCookie('jwt_token', mytoken, null); 

//         console.log('..token renewed on '+new Date());
//     }
//     else if (this.readyState == 4 && this.status != 200) {
//         console.log(`renew jwt failed or token expired. Error code ${this.status} ${this.statusText} `);
//         //alert('Maaf token anda sudah tamat tempoh. Sila login semula.');
//         // location.href='./login.html';  //kat app tukar dir ni
//     }
//     };
//     xhttp.open("POST", "https://temptunnel1.shaz.my/api/users/renewjwt", true);
//     xhttp.setRequestHeader("Content-Type","application/json");
//     xhttp.setRequestHeader("Authorization","Bearer "+window._getCookie('jwt_token'));    
//     xhttp.send();

// }




/////////////